<template>
  <FocusTrap>
    <div class="card">
      <div class="card-header header-elements-sm-inline py-sm-0" >
        <h5 class="card-title py-sm-3"><span class="icon-list"></span>&nbsp;&nbsp;Receipt's List</h5>
        <div class="header-elements">
          <form action="#">
            <div class="form-group-feedback form-group-feedback-right">
              <input  id="txtsearch" type="search" class="form-control wmin-sm-200" placeholder="Search..." autocomplete="off">
              <div class="form-control-feedback">
                <i class="icon-search4 font-size-base text-muted"></i>
              </div>
            </div>
          </form>
        </div>
      </div>

    <div class="table-responsive">
      <table id="receipt-table"
             class="table table-bordered table-columned"
             data-search="false"
             data-pagination="false"
             data-show-refresh="false"
             data-show-columns="false"
             data-page-list="[10, 25, 50, 100, ALL]"
             data-show-footer="false"
             data-toggle="context"
             data-target=".context-table">
        <thead>
        <tr>
          <th data-field="id" data-class="d-none">id</th>
          <th data-formatter="runningFormatter" data-sortable="true" data-width="75" >S.No</th>
          <th data-width="100" data-field="doc_no" data-sortable="true" data-align="center">Doc No</th>
          <th data-width="107" data-field="doc_date" data-sortable="true" data-formatter="dateFormatter" >Date</th>
          <th data-field="party.name" data-sortable="true">Party</th>
          <th data-field="received_ledger.name" data-sortable="true">Received By</th>
          <th data-field="amount" data-width="200" data-align="right" data-sortable="true" data-formatter="indianFormat">Amount</th>
          <th data-field="remarks" data-sortable="true">Narration</th>
        </tr>
        </thead>
        <tbody>
        </tbody>
        <tfoot>
        </tfoot>
      </table>
    </div>

    <!-- Context Menu -->
    <div id="context-menu" class="context-table">
      <div class="dropdown-menu">
        <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: darkgreen"></i>Refresh</a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item" style="color: blue;"> <i class="icon-pencil3" ></i>Modify</a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item" style="color: red"> <i class="icon-blocked" ></i>Cancel</a>
      </div>
    </div>
    <!-- End of Context Menu -->

    <FocusTrap>
    <vmodal name="partyreceipt_window" transition="nice-modal-fade" :delay="100" :resizable="true" width="75%" :height="475" @before-open="beforeOpen" @before-close="beforeClose" >
      <PartyReceiptForm v-bind:myreceipt="receipt"  v-on:partyreceipt_saved="loadData" v-on:partyreceipt_window_closed="closeModal" ></PartyReceiptForm>
    </vmodal>
    </FocusTrap>

    <!-- Clickable menu -->
    <ul class="fab-menu fab-menu-fixed fab-menu-bottom-right is_stuck" data-fab-toggle="hover" z-index="0">
      <li>
        <a class="fab-menu-btn btn bg-primary-600 btn-float rounded-round btn-icon" @click="showModal">
          <i class="fab-icon-open icon-plus3"></i>
          <i class="fab-icon-close icon-plus3"></i>
        </a>
      </li>


    </ul>
    <!-- /clickable menu -->


  </div>
  </FocusTrap>
</template>

<script>
import moment from 'moment-timezone'
import PartyReceiptForm from './PartyReceiptForm'
import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'

export default {
  name: 'PartyReceiptReceiptView',
  components: {
    PartyReceiptForm
  },
  store,
  data () {
    return {
      mytable: {},
      receipt: JSON.parse('{"id":0,"finyear":0,"doc_no":"","doc_date":"0001-01-01","ref_no":"","ref_date":"0001-01-01","party_id":0,"party":{"id":0,"type":0,"name":""},"rcpt_ldgr_id":0,"received_ledger":{"id":0,"type":0,"name":""},"amount":0,"remarks":"","brn_id":0,"cmp_id":0,"locked":false,"list":[]}'),
      branches: [],
      selected_ibr: 1,
    }
  },
  created () {

  },
  mounted () {
    const self = this;

    self.$data.receipt.doc_date = moment().format('YYYY-MM-DD');
    self.$data.receipt.ref_date = moment().format('YYYY-MM-DD');

    self.$data.mytable = $('#receipt-table');


    this.$data.mytable.bootstrapTable();
    $('[data-toggle="popover"]').popover();

    $('#receipt-table').contextmenu({
      target: '#context-menu',
      scopes: 'tbody > tr',
      onItem: function (row, e) {
        let id = $(row.children('*')[0]).text();

        if ($(e.target).text() === 'Modify') {
          self.modifyVoucher(id);
        } else if ($(e.target).text() === 'Cancel') {
          self.cancelVoucher(id);
        }
      }
    });

    $('#txtsearch').keyup(function () {
      let val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();

      $('#receipt-table>tbody>tr').show().filter(function () {
        let text = $(this).text().replace(/\s+/g, ' ').toLowerCase();
        return !~text.indexOf(val);
      }).hide();

    });


    self.loadData();
  },
  methods: {
    showModal () {
      this.$modal.show('partyreceipt_window');
    },
    beforeOpen () {

    },
    beforeClose () {
      this.receipt = JSON.parse('{"id":0,"finyear":0,"doc_no":"","doc_date":"0001-01-01","ref_no":"","ref_date":"0001-01-01","party_id":0,"party":{"id":0,"type":0,"name":""},"rcpt_ldgr_id":0,"received_ledger":{"id":0,"type":0,"name":""},"amount":0,"remarks":"","brn_id":0,"cmp_id":0,"locked":false,"list":[]}');
    },
    closeModal () {
      this.$modal.hide('partyreceipt_window')
    },
    loadData () {
      const self = this;
      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#txtsearch').val('');

      $('#mycard').block({
        msg: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });


      let ibr = self.$store.state.xpos.branch.id;

      self.$data.mytable.bootstrapTable('load', []);
      self.$data.mytable.bootstrapTable('showLoading');

      fetch(`${process.env.VUE_APP_ROOT_API}api/xpos/partyreceipts?finyear=${self.$store.state.xpos.finyear}&brn_id=${ibr}`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (_.isArray(resp.data)) {
            self.$data.mytable.bootstrapTable('load', resp.data);
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      }).finally(function () {
        self.$data.mytable.bootstrapTable('hideLoading');
        $('#mycard').unblock()
      })
    },
    modifyVoucher (id) {
      let self = this
      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      self.$data.receipt = {}
      fetch(`${process.env.VUE_APP_ROOT_API}v1/xpos/partyreceipt/${id}`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          self.$data.receipt = resp.data;
          self.$data.receipt.doc_date = resp.data.doc_date.slice(0, 10);

          self.$data.receipt.details.forEach(function (det, i) {
            if (det.idx == 0) {
              self.$data.receipt.ledger = det.ledger;
              self.$data.receipt.details.splice(i, 1);
            }
          })

          self.$modal.show('partyreceipt_window');
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' })
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' })
      })
    },
    cancelVoucher (id) {
      let self = this

      self.$data.receipt.id = id

      const requestOptions = {
        method: 'DELETE',
        mode: 'cors',
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.receipt)
      }

      swal({
        title: 'What is the reason for cancel this receipt?',
        input: 'text',
        inputPlaceholder: 'Enter Remarks here',
        showCancelButton: true,
        inputClass: 'form-control',
        inputValidator: function (value) {
          return !value && 'You need to write something!'
        }
      }).then(function (result) {
        if (result.value) {
          fetch(`${process.env.VUE_APP_ROOT_API}xpos/partyreceipt?${id}&remarks=${result.value}`, requestOptions).then(userService.handleResponse).then(function (resp) {
            self.$modal.hide('partyreceipt_window');
            if (resp.ok) {
              self.loadData();
              swal({
                title: 'Canceled!',
                text: 'Your request has been processed',
                type: 'success'
              })
            } else {
              swal({ title: 'Oops', text: resp.msg, type: 'info' })
            }
          }).catch(function (err) {
            swal({ title: 'Oops', text: err.toString(), type: 'error' })
          })
        }
      })
    },
  }
}

</script>

<style scoped>

</style>
