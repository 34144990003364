<template>
  <FocusTrap>
    <div  id="mycard" class="card">

      <div class="card-header header-elements-inline" style="background-color: whitesmoke;padding: 10px;" >
        <h5 v-if="voucher.id == 0" class="card-title">Party Receipt Creation</h5>
        <h5 v-if="voucher.id > 0" class="card-title"> Doc No: {{voucher.doc_no}}  Party Receipt Updation</h5>
        <div class="header-elements">
          <div class="list-icons">
            <a class="list-icons-item" data-action="reload" @click="loadAll" ></a>
            <a class="list-icons-item" data-action="remove" @click="closeThis"></a>
          </div>
        </div>
      </div>

      <div class="card-body">

        <div class="row">

          <div class="col-md-3">
            <div class="form-group form-group-material">
              <label class="control-label text-semibold">Party</label>
              <select id="cmbledger" class="form-control select" autofocus="" required="" v-if="voucher" v-model="voucher.party_id" >
                <option v-for="ledger in buyers" v-bind:value="ledger.id">
                  {{ ledger.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-8">
          </div>

          <div class="col-md-1">
            <div class="form-group form-group-material">
              <label class="control-label text-semibold">Date</label>
              <input id="txtDate" type="date" class="form-control" v-if="voucher" v-model="voucher.doc_date">
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-3">
            <div class="form-group form-group-material">
              <label class="control-label text-semibold">Amount</label>
              <input id="txtamount" type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00"   v-if="voucher" v-model="voucher.amount"/>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group form-group-material">
              <label class="control-label text-semibold">Outlet</label>
              <select class="form-control">
                <option value="0">All</option>
                <option v-for="outlet in outlets" v-bind:value="outlet.id">
                  {{ outlet.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group form-group-material">
              <label class="control-label text-semibold">Receiving Ledger</label>
              <select class="form-control" required="" v-if="voucher" v-model="voucher.rcpt_ldgr_id" >
                <option v-for="ledger in ledgers" v-bind:value="ledger.id">
                  {{ ledger.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-md-1" style="align-items: center">
            <label class="control-label">&nbsp;Reload</label>
            <button class="btn btn-outline-success form-control" data-toggle="tooltip" title="Click To load Bills" @click="loadPendingBills"  > <span class="icon-reload-alt"></span> </button>
          </div>
          <div class="col-md-2">
          </div>

        </div>

        <div class="row">
          <table class="table table-bordered table-columned table-hover">
            <thead style="background-color: lightgrey">
            <tr>
              <th style="width:50px;">S.No</th>
              <th >Outlet</th>
              <th >Bill No</th>
              <th >Date</th>
              <th >Vehicle No</th>
              <th >Bill Amount</th>
              <th >Cashier</th>
              <th >Balance</th>
              <th style="width:150px; text-align: right;">Rcpt Amt</th>
<!--              <th style="width: 30px;">Action</th>-->
            </tr>
            </thead>
            <tbody>
              <tr v-for="(bill,i) in pendingbills" :key="i">
                <td>{{i + 1}}</td>
                <td>{{bill.branch_name}}</td>
                <td>{{bill.doc_no}}</td>
                <td>{{bill.bill_date | dateFormatter}}</td>
                <td>{{bill.vehicle_no}}</td>
                <td class="text-right">{{bill.bill_amt}}</td>
                <td>{{bill.cashier}}</td>
                <td class="text-right">{{bill.balance_amt}}</td>
                <td style="padding: 0px;"><input class="form-control text-right" type="number" :max="bill.balance_amt" v-model="bill.rcpt_amt" /></td>
<!--                <td style="padding: 0px;text-align: right;">-->
<!--                  <button name="btnRemoveRow"   type="button" class="btn border-warning text-warning-600 btn-flat btn-icon btn-rounded legitRipple btnRemoveRow" @click="removeRow(index)" tabindex="-1" ><i class="icon-eraser"></i></button>-->
<!--                </td>-->
              </tr>
            </tbody>
            <tfoot></tfoot>
          </table>
        </div>

        <div class="row" style="padding-top: 5px;">
          <div class="col-md-3">
            <div class="form-group form-group-material">
              <label class="control-label text-semibold">Narration</label>
              <textarea id="txtremarks" class="form-control" placeholder="Narration"  v-if="voucher" v-model="voucher.remarks" > </textarea>
            </div>
          </div>

          <div class="col-md-6">
          </div>

          <div class="col-md-3 text-right">
            <button id="btnSave" type="button" class="btn bg-green-800 btn-raised active" @click="perform_save_action">Save</button>
            <button type="button" id="btnclose" class="btn btn-default" data-dismiss="" @click="clear" >Clear</button>
          </div>
        </div>
      </div>

    </div>
  </FocusTrap>
</template>

<script>
import moment from 'moment-timezone'
import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'
export default {
  name: 'partyreceiptform',
  store,
  components: {

  },
  data () {
    return {
      readonly: false,
      buyers: [],
      outlets:[],
      ledgers:[],
      pendingbills:[],
      voucher: JSON.parse('{"id":0,"finyear":0,"doc_no":"","doc_date":"0001-01-01","ref_no":"","ref_date":"0001-01-01","party_id":0,"party":{"id":0,"type":0,"name":""},"rcpt_ldgr_id":0,"received_ledger":{"id":0,"type":0,"name":""},"amount":0,"remarks":"","brn_id":0,"cmp_id":0,"locked":false,"list":[]}'),
    }
  },
  props: {
    myvoucher: {
      type: Object,
      default: () => JSON.parse('{"id":0,"finyear":0,"doc_no":"","doc_date":"0001-01-01","ref_no":"","ref_date":"0001-01-01","party_id":0,"party":{"id":0,"type":0,"name":""},"rcpt_ldgr_id":0,"received_ledger":{"id":0,"type":0,"name":""},"amount":0,"remarks":"","brn_id":0,"cmp_id":0,"locked":false,"list":[]}')
    }
  },
  beforeMount () {
    this.voucher = this.myvoucher;
  },
  created () {
    this.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
    this.$data.voucher.ref_date = moment().format('YYYY-MM-DD');
  },
  mounted () {
    const self = this;

    self.loadBuyer();
    self.loadOutlet();
    self.loadReceivedLedgers();

    $('[data-toggle="tooltip"]').tooltip();

    if (self.$data.voucher.doc_date == '0001-01-01') {
      this.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
      this.$data.voucher.ref_date = moment().format('YYYY-MM-DD');
    }

    $('#cmbledger').focus();

  },
  methods: {
    indianFormat (val) {
      return parseFloat(val).toFixed(2)
    },
    closeThis () {
      this.$emit('partyreceipt_window_closed');
      if (this.voucher.id == 0) {
        this.$router.push('/');
      }
    },
    clear () {
      const self = this;
      self.$data.detail = JSON.parse('{"idx": 0, "adjusttype": 0, "ledger": {"_id": "", "id": 0, "status": 0, "type": 0, "name": "", "alias": "", "group": {"_id": "", "id": 0, "status": 0, "type": 0, "name": "", "alias": "", "parent": 0, "nature": 0, "level": 0, "is_profit": false, "is_subledger": false, "is_net_bal": false}, "address": {"name": "", "street": "", "city": "", "pin": "", "state_id": 0, "email": "", "tel": "", "mobile": ""}, "open_bal": 0, "gstin": ""}, "desc": "", "debit": 0, "credit": 0}');
      self.$data.voucher = JSON.parse('{"id":0,"finyear":0,"doc_no":"","doc_date":"0001-01-01","ref_no":"","ref_date":"0001-01-01","party_id":0,"party":{"id":0,"type":0,"name":""},"rcpt_ldgr_id":0,"received_ledger":{"id":0,"type":0,"name":""},"amount":0,"remarks":"","brn_id":0,"cmp_id":0,"locked":false,"list":[]}');
      self.$data.voucher.list = [];
      self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
      self.$data.voucher.ref_date = moment().format('YYYY-MM-DD');
    },
    loadOutlet () {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#mycard').block({
        msg: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });


      self.outlets = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/xpos/activeoutlets`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (_.isArray(resp.data)) {

            resp.data.forEach(function (ldgr) {

              self.$data.outlets.push(ldgr);
              // if(self.$store.state.xpos.userledger.id !== ldgr.id){
              //   self.$data.buyers.push(ldgr);
              // }
            });

          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
        $('#mycard').unblock();
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      })
    },
    loadBuyer () {
      const self = this;

      const requestOptions = {
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#mycard').block({
        msg: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });


      // fetch the buyers
      self.$data.buyers = [];

      fetch(`${process.env.VUE_APP_ROOT_API}api/xpos/customers`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (_.isArray(resp.data)) {

            // self.$data.buyers = resp.data;
            resp.data.forEach(function (ldgr) {
              ldgr.id = ldgr.id;
              self.$data.buyers.push(ldgr);
              // if(self.$store.state.xpos.userledger.id !== ldgr.id){
              //   self.$data.buyers.push(ldgr);
              // }
            });
          }
        } else {
          swal({ title: 'Oops', text: resp.msg, type: 'error' })
        }
        $('#mycard').unblock();
      }).catch(function (err) {
        swal({ title: 'Oops', text: err.toString(), type: 'error' })
      });
    },
    loadReceivedLedgers () {
      const self = this;

      const requestOptions = {
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#mycard').block({
        msg: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });


      // fetch the Ledgers
      self.$data.ledgers = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/accounts/cash_bank_ledgers`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (_.isArray(resp.data)) {
            // self.$data.ledgers = resp.data;
            resp.data.forEach(function (ldgr) {
              ldgr.id = ldgr.id;
              self.$data.ledgers.push(ldgr);
            });

          }
        } else {
          swal({ title: 'Oops', text: resp.msg, type: 'error' })
        }
        $('#mycard').unblock();
      }).catch(function (err) {
        swal({ title: 'Oops', text: err.toString(), type: 'error' })
      });
    },
    loadAll(){
      const self = this;
      self.loadOutlet();
      self.loadBuyer();
      self.loadReceivedLedgers();
    },
    loadPendingBills () {
      const self = this;

      const requestOptions = {
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#mycard').block({
        msg: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      // fetch the Pending Bills
      self.$data.pendingbills = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/xpos/customer/pendingbills/${self.$data.voucher.party_id}`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (_.isArray(resp.data)) {
            self.$data.pendingbills = resp.data;
            self.offset_received_amount();
          }
        } else {
          swal({ title: 'Oops', text: resp.msg, type: 'error' })
        }
        $('#mycard').unblock();
      }).catch(function (err) {
        swal({ title: 'Oops', text: err.toString(), type: 'error' })
      });
    },
    offset_received_amount(){
      const self = this;

      let cur_amt = self.voucher.amount;

      self.$data.pendingbills.forEach((line) =>{
        line.bill_date = dateFormatter(line.bill_date);


        if( parseFloat(line.balance_amt) <= cur_amt ) {
          cur_amt = cur_amt - parseFloat(line.balance_amt);
          line.rcpt_amt = parseFloat(line.balance_amt);
        }else if(cur_amt >= 0 ) {
          line.rcpt_amt = cur_amt;
          cur_amt = 0;
        }
      });

    },
    perform_save_action () {
      const self = this;

      try {

        if(self.$data.voucher.list.length == 0) {
          alert('Invalid Amount Breakups');
          return;
        }else if(self.voucher.party_id < 1) {
          $('#cmbledger').focus();
          alert('Invalid Party');
          return;
        }else if(self.voucher.rcpt_ldgr_id < 1) {
          $('#cmbledger').focus();
          alert('Invalid Received Ledger');
          return;
        }else if( parseFloat(self.voucher.amount) <= 0 ){
          $('#txtamount').focus();
          alert('Invalid Amount');
          return;
        }

        let myvoucher = JSON.parse(JSON.stringify(self.$data.voucher));
        myvoucher.finyear = self.$store.state.user.finyear;
        myvoucher.brn_id = parseInt(self.$store.state.user.branch.id);
        myvoucher.cmp_id = parseInt(self.$store.state.user.company.id);
        myvoucher.doc_date = moment(myvoucher.doc_date).format('YYYY-MM-DD');// + 'T00:00:00Z'
        myvoucher.ref_date = moment(myvoucher.doc_date).format('YYYY-MM-DD');// + 'T00:00:00Z'
        myvoucher.party_id = parseInt(self.$data.voucher.party_id);
        myvoucher.rcpt_ldgr_id = parseInt(self.$data.voucher.rcpt_ldgr_id);
        myvoucher.amount = parseFloat(self.$data.voucher.amount);

        const requestOptions = {
          method: ((myvoucher.id == 0) ? 'POST' : 'PUT'),
          mode: 'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(myvoucher)
        }

        $('#mycard').block({
          msg: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        fetch(`${process.env.VUE_APP_ROOT_API}api/xpos/partyreceipt`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            swal({ title: 'Success', type: 'success', text: resp.msg, onClose: () => { $('#cmbledger').focus()}, timer:1500  });
            self.clear();
            self.$emit('partyreceipt_saved', resp.data);

          } else {
            swal({ title: 'Oops', text: resp.msg, type: 'error' , onClose: () => { $('#cmbledger').focus()}, timer:3000 });
          }
        }).catch(function (err) {
          swal({ title: 'Oops', text: err.toString(), type: 'error', onClose: () => { $('#cmbledger').focus()}, timer:3000  });
        }).finally(function () {
          $('#mycard').unblock();
        });
      }catch (e) {
        alert(e);
      }
    },
  }
}
</script>

<style scoped>
  input:focus {
    background: #feff00;

  }

  textarea:focus {
    background: #feff00;

  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }
</style>
